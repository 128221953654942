<template>
  <div class="file">
    <div v-if="isPopup" class="mask"></div>

    <div v-if="isPopup" class="popup">
      <h1>请将身份证放置在读卡区！</h1>
      <el-button class="home" style="background-color: #0e59d4;" type="primary" @click.native="home">首页</el-button>
    </div>
    <el-row class="head">建档绑卡</el-row>
    <count></count>
    <div v-if="!isPopup" class="info">
      <div class="idCard">
        <span>请刷身份证或者手动输入身份信息</span>
      </div>
    </div>
    <div v-if="!isPopup" class="box">
      <div class="message">
        <div class="file_row">
          <span>姓名：</span>
          <el-input v-model="custName" style="width: 20% ;height:30px"></el-input>
          <span style="margin-left: 130px;">性别：</span>
          <el-select v-model="value" placeholder="请选择" style="width: 20%;" @change="select">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="file_row">
          <span>身份证号码:</span>
          <el-input v-model="certNbr" class="cdrNum" @blur="getAgeNum" @focus="inputClick(0)"></el-input>
        </div>
        <div class="file_row">
          <!-- <span>民族：</span>
          <el-input v-model="nation" disabled style="width: 20%;"></el-input> -->
          <span>出生年月：</span>
          <el-input v-model="birthday" class="cdrNum" @focus="inputClick(3)"></el-input>
        </div>
        <div class="tip">身份证输入格式为：xxxx-xx-xx</div>

        <!-- <div class="row">
          <span>地址:</span>
          <el-input v-model="certAddr"  style="width: 88%;"></el-input>
        </div> -->
        <div class="file_row">
          <span>联系电话:</span>
          <el-input ref="tel" v-model="telephone" class="cdrNum" @focus="inputClick(1)"></el-input>
        </div>
      </div>
      <div class="key">
        <el-button style="margin-left:7px" type="primary" @click.native="inputNum(1)">1</el-button>
        <el-button type="primary" @click.native="inputNum(2)">2</el-button>
        <el-button type="primary" @click.native="inputNum(3)">3</el-button>
        <el-button type="primary" @click.native="inputNum(4)">4</el-button>
        <el-button type="primary" @click.native="inputNum(5)">5</el-button>
        <el-button type="primary" @click.native="inputNum(6)">6</el-button>
        <el-button type="primary" @click.native="inputNum(7)">7</el-button>
        <el-button type="primary" @click.native="inputNum(8)">8</el-button>
        <el-button type="primary" @click.native="inputNum(9)">9</el-button>
        <el-button type="primary" @click.native="inputNum('X')">X</el-button>
        <el-button type="primary" @click.native="inputNum(0)">0</el-button>
        <el-button type="primary" @click.native="inputNum('-')">-</el-button>
        <el-button style="background-color: #009944;" type="primary" @click.native="addFile">确认</el-button>
        <el-button style="background-color: #f17e58;" type="primary" @click.native="clear">清空</el-button>
        <el-button style="background-color: #49b9e1;" type="primary" @click.native="del">退格</el-button>
      </div>
    </div>
    <div v-if="!isPopup" class="tips">
      <h3>温馨提示：</h3>
      <p>请您使用数字键盘输入卡号或身份证，并点击确认按钮</p>
    </div>
    <div v-if="!isPopup" class="bottom"></div>
    <el-button class="back" type="primary" @click.native="back">返回</el-button>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>

<script>
import Foo from '../footer/index'
import Count from '../count/index'

import { userBindRecordAdd } from '@/service/api'
import qs from 'qs'

export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      telephone: '',
      disabled: false,
      custName: '',
      options: [
        {
          value: 1,
          label: '男'
        },
        {
          value: 2,
          label: '女'
        }
      ],
      value: '',
      sexVal: '',
      birthday: '',
      certAddr: '',
      certNbr: '',
      age: '',
      input: '',
      isPopup: false,
      timer: null,
      inputType: 0
    }
  },
  created() {
    this.name = this.$route.query.name
    if (this.$route.query.certNumber) {
      this.ReadIDCard()
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.tel.focus();
    // });
    // this.timer = setInterval(
    //   this.ReadIDCard, 2000
    // );
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    getAgeNum() {
      this.age = this.GetAge(this.certNbr)
      this.birthday = this.birthdayNum(this.certNbr)
      let date = new Date()
      let year = date.getFullYear()
      this.birthday = this.certNbr.substring(6, 10) + '-' + this.certNbr.substring(10, 12) + '-' + this.certNbr.substring(12, 14)
    },
    select(e) {
      this.sexVal = e
    },
    inputClick(num) {
      this.inputType = num
    },
    async ReadIDCard() {
      this.custName = this.$route.query.partyName
      this.value = this.$route.query.gender
      this.sex = this.$route.query.gender == 1 ? '男' : '女'
      this.sexVal = this.$route.query.gender == '男' ? '1' : '0'
      this.certNbr = this.$route.query.certNumber
      this.birthday = this.certNbr.substring(6, 10) + '-' + this.certNbr.substring(10, 12) + '-' + this.certNbr.substring(12, 14)
      this.age = this.GetAge(this.$route.query.certNumber)
    },
    birthdayNum(str) {
      var reg = /^(\d{4})(\d{2})(\d{2})$/
      str = str.replace(reg, '$1-$2-$3')
      return str
    },
    async addFile() {
      let _this = this
      if (this.telephone === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.telephone)) {
        this.$message({
          message: '手机号有误，请重新输入',
          type: 'error'
        })
      } else if (this.birthday === '') {
        this.$message({
          message: '请输入出生年月',
          type: 'error'
        })
      } else if (this.certNbr === '') {
        this.$message({
          message: '请填写身份证',
          type: 'error'
        })
      } else {
        this.$message({
          message: '正在建档',
          type: 'info'
        })
        const data = {
          address: encodeURI(this.certAddr),
          age: this.age,
          Birthday: this.birthday,
          CardNo: this.certNbr,
          cardType: '01',
          isAdult: this.age >= 18 ? 1 : 2,
          isSelf: 1,
          patCardType: '01',
          Name: encodeURI(this.custName),
          sex: this.sexVal,
          Telephone: this.telephone
        }
        await userBindRecordAdd(qs.stringify(data, { encode: false })).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.$message({
              message: '建档成功',
              type: 'success'
            })
            this.$router.push('/')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    GetAge(identityCard) {
      var len = (identityCard + '').length
      if (len == 0) {
        return 0
      } else {
        if (len != 15 && len != 18) {
          //身份证号码只能为15位或18位其它不合法
          return 0
        }
      }
      var strBirthday = ''
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday = identityCard.substr(6, 4) + '/' + identityCard.substr(10, 2) + '/' + identityCard.substr(12, 2)
      }
      if (len == 15) {
        strBirthday = '19' + identityCard.substr(6, 2) + '/' + identityCard.substr(8, 2) + '/' + identityCard.substr(10, 2)
      }
      //时间字符串里，必须是“/”
      var birthDate = new Date(strBirthday)
      var nowDateTime = new Date()
      var age = nowDateTime.getFullYear() - birthDate.getFullYear()
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    inputNum(num) {
      if (this.inputType == 0) {
        this.certNbr += num
      } else if (this.inputType == 1) {
        this.telephone += num
      } else if (this.inputType == 3) {
        this.birthday += num
      }
    },
    del() {
      if (this.inputType == 0) {
        this.certNbr = this.certNbr.substr(0, this.certNbr.length - 1)
      } else if (this.inputType == 1) {
        this.telephone = this.telephone.substr(0, this.telephone.length - 1)
      } else if (this.inputType == 3) {
        this.birthday = this.telephone.substr(0, this.telephone.length - 1)
      }
    },
    clear() {
      if (this.inputType == 0) {
        this.certNbr = ''
      } else if (this.inputType == 1) {
        this.telephone = ''
      } else if (this.inputType == 3) {
        this.birthday = ''
      }
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.el-select-dropdown__item {
  margin: 30px 0;
  font-size: 40px;
}

/deep/ .el-input__inner {
  border-color: #3b77d8 !important;
  margin-left: 15px;
  border: #3172ff 2px solid !important;
  border-radius: 8px;
  height: 100%;
  font-size: 46px !important;
  color: #000000 !important;
}

/deep/ .el-input--suffix {
  height: 80px;
  top: -9px !important;
}

/deep/ .el-scrollbar__view {
  height: 70px !important;
  color: #ff3265;
}

.file {
  position: relative;
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.file .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 1;
}

.file .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3c6ee61f;
  width: 60%;
  height: 40%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.file .popup h1 {
  text-align: center;
  line-height: 280px;
}

.file .head {
  height: 130px;
  line-height: 130px;
  text-align: center;
  letter-spacing: 12px;
  font-size: 46px;
  font-weight: 700;
  background-image: linear-gradient(to right, #3172ff 50px, #92dfff);
  color: #ffffff;
}

.file .info {
  margin-top: 35px;
  margin: 0 auto;
}

.idCard {
  text-align: center;
}

.file .info .idCard span {
  font-size: 62px;
  color: #00cc66;
  font-weight: bold;
  margin-left: 80px;
}

.message {
  margin: 8% 0 40px auto;
}

.file .box .message .file_row {
  height: 80px;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.file .box .message .file_row .cdrNum {
  width: 50%;
  height: 100px;
  font-weight: bold;
}

.file .box .message .file_row span {
  font-weight: bold;
  font-size: 46px;
  color: white;
  line-height: 80px;
}

.file .box .key {
  text-align: center;
  margin: 0 auto;
  width: 60%;
}

.file .box .key .el-button {
  margin-top: 25px;
  width: 30%;
  height: 150px;
  font-size: 44px;
  background-color: #3b77d8;
}

.file .tips {
  color: #ff3265;
  font-size: 40px;
  padding: 40px;
  //margin :0 auto;
  text-align: center;
}

.tip {
  text-align: center;
  font-size: 40px;
  margin-top: 20px;
}

.file .bottom {
  margin-left: 600px;
  padding: 0 20px;
}

.file .bottom .el-button {
  width: 150px;
  height: 60px;
  margin-left: 40px;
  font-size: 32px;
}

.file .footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
